<template>
<a-layout>
    <a-layout-content>
        <a-row class="usersetting" v-bind:class="[ _mobile  ? '' : 'pc' ]">
            <a-col :span="span" :offset="offSet">
                <div style="display: table;margin: 0 auto; max-width: 394px;   width: 92%;">
                    <div style="margin-top:60px">
                        <div class="s-u-font1">시스템 설정</div>
                        <div class="s-u-font2">라이센스(남은 일수)</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="remain" disabled> </a-input>
                        </div>
                        <div class="s-u-font2">네어버스토어 관심제품 등록수</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="count.naver" disabled> </a-input>
                        </div>
                        <div class="s-u-font2">쿠팡 관심제품 등록수</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="count.coupang" disabled> </a-input>
                        </div>
                        <div class="s-u-font2">티몬 관심제품 등록수</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="count.tmon" disabled> </a-input>
                        </div>
                        <div class="s-u-font2">카카오쇼핑 관심제품 등록수</div>
                        <div class="s-u-d1">
                            <a-input type="text" v-model="count.kakao" disabled> </a-input>
                        </div>

                        <div class="s-u-btn2">
                            <a-tooltip>
                                <template slot="title">

                                    장기간 순위에 들지 않는 경우 집계에서 제외가 됩니다. 집계를 다시 원하시는 경우 초기화를 클릭 해주세요
                                </template>
                                <a-button @click="onInitCountForPaidUser()">

                                    관심등록 자동 집계 정보 초기화
                                </a-button>
                            </a-tooltip>

                        </div>
                    </div>
                </div>

            </a-col>
        </a-row>
    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

import {
  getLicense,
  replaceAll,
  getCurrentTime,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      span: 8,
      offSet: 8,
      count: {
        naver: 0,
        kakao: 0,
        tmon: 0,
        coupang: 0
      },
      remain: 0,
      currCount: 0
    };
  },
  async mounted() {
    fbq("track", "PageView");
    this.$vs.loading();
    this.onLoadLicense();
    await this.onLoadFavoriteList();
    await this.onLoadFavoriteList_kakao();
    await this.onLoadFavoriteList_coupang();
    await this.onLoadFavoriteList_tmon();
    this.$vs.loading.close();
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getPhone() {
      return this.$store.state.AppActiveUser.phone;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getCompayName() {
      return this.$store.state.AppActiveUser.companyName;
    },
    getCurrCount() {
      return this.$store.state.AppActiveUser.currCount;
    },
    getLevel() {
      return this.$store.state.AppActiveUser.level;
    },
    getRemain() {
      return this.$store.state.AppActiveUser.remain;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;
      this.offSet = _mobile ? 0 : 8;
      return _mobile;
    }
  },
  methods: {
    onLoadLicense() {
      this.currCount = this.getCurrCount ? this.getCurrCount : this.getLevel;
      this.remain = this.getRemain;
    },
    async onLoadFavoriteList() {
      var self = this;

      var sref2 = firebase.database().ref("execution_master/");
      await sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count.naver++;
            }
          });
        });
    },
    async onLoadFavoriteList_kakao() {
      var self = this;

      var sref2 = firebase.database().ref("execution_master_kakao/");
      await sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count.kakao++;
            }
          });
        });
    },
    async onLoadFavoriteList_coupang() {
      var self = this;

      var sref2 = firebase.database().ref("execution_master_coupang/");
      await sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count.coupang++;
            }
          });
        });
    },
    async onLoadFavoriteList_tmon() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master_tmon/");

      await sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count.tmon++;
            }
          });
        });
    },
    onInitCountForPaidUserNaver() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
          self.$vs.loading.close();
        });
    },
    onInitCountForPaidUserKakao() {
      var sref = firebase.database().ref("auto_execution_master_kakao/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_kakao/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserCoupang() {
      var sref = firebase.database().ref("auto_execution_master_coupang/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_coupang/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserTmon() {
      var sref = firebase.database().ref("auto_execution_master_tmon/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_tmon/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUser() {
      var self = this;
      this.$vs.loading();
      this.onInitCountForPaidUserNaver();
      this.onInitCountForPaidUserKakao();
      this.onInitCountForPaidUserCoupang();
      this.onInitCountForPaidUserTmon();
      showSuccess({
        notify: self.$vs.notify,
        msg: "정상적으로 초기화 되었습니다."
      });
    }
  }
};
</script>

<style>
.usersetting input {
  height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
  border-radius: 5px !important;
}

.s-u-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  color: #000000;
}

.s-u-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.58px;
  color: #7d7d7d;
}

.s-u-d1 {
  margin-top: -5px;
}

.s-u-btn1 button,
.s-u-btn1 button:hover {
  margin-top: 30px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #0264fb;
  color: white;
}

.s-u-btn2 button {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
}
</style>
